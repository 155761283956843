import React from "react";

const MagnifierPlus = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7469_37395)">
      <path
        d="M40.1291 38.3282L29.1076 27.2394C31.4676 24.4207 32.8882 20.7847 32.8882 16.8147C32.8882 7.85664 25.6647 0.593262 16.7066 0.593262C7.74858 0.593262 0.48645 7.85539 0.48645 16.8134C0.48645 25.7715 7.74858 33.0336 16.7066 33.0336C20.7962 33.0336 24.5208 31.5159 27.3643 29.02L38.3659 40.0901C38.8519 40.5761 39.6419 40.5761 40.1279 40.0901C40.6138 39.6042 40.6151 38.8142 40.1279 38.3269L40.1291 38.3282ZM16.6854 30.5003C9.14917 30.5003 2.9786 24.331 2.9786 16.7935C2.9786 9.25599 9.14917 3.08666 16.6854 3.08666C24.2217 3.08666 30.3923 9.25723 30.3923 16.7935C30.3923 24.3298 24.223 30.5003 16.6854 30.5003ZM22.9158 15.5474H17.9315V10.5631C17.9315 9.87529 17.3733 9.31704 16.6854 9.31704C15.9976 9.31704 15.4394 9.87529 15.4394 10.5631V15.5474H10.4551C9.76723 15.5474 9.20898 16.1057 9.20898 16.7935C9.20898 17.4813 9.76723 18.0396 10.4551 18.0396H15.4394V23.0239C15.4394 23.7117 15.9976 24.27 16.6854 24.27C17.3733 24.27 17.9315 23.7117 17.9315 23.0239V18.0396H22.9158C23.6037 18.0396 24.1619 17.4813 24.1619 16.7935C24.1619 16.1057 23.6037 15.5474 22.9158 15.5474Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7469_37395">
        <rect
          width="39.8744"
          height="39.8744"
          fill="white"
          transform="translate(0.619934 0.594727)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MagnifierPlus;