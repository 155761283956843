import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import LoadContent from "../../../../components/LoadContent";
import ZoomImage from "./components/ZoomImg/ZoomImage";
import useWindowSize from "components/useWindowSize";
import { Zoom } from "swiper/modules";
import "swiper/css/zoom";

export default function ImgBlock({
  imgs,
  loaded,
  setModalImg,
  setIsOpenModalImg,
  imageBlockStyle,
  inModal,
  activeImg,
  setActiveImg,
}) {
  const { width: windowWidth } = useWindowSize();
  const [imageNotFound, setImageNotFound] = useState(null);
  const swiperRef = useRef();
  const thumbnailSwiperRef = useRef();
  const [isOverImage, setIsOverImage] = useState(false);
  const [zoomResetFlag, setZoomResetFlag] = useState(false);

  const handleOpenModal = (index) => {
    setModalImg(index);
    setIsOpenModalImg(true);
  };

  const checkImageExists = (image) => {
    let checkImage;
    let thumbImage;
    if (typeof image === "string") {
      thumbImage = true;
      checkImage = process.env.REACT_APP_STORAGE_URL + image;
    } else {
      checkImage = process.env.REACT_APP_STORAGE_URL + image?.images_source;
    }

    const img = new Image();
    img.src = checkImage;

    img.onload = () => {
      setImageNotFound(null);
    };

    img.onerror = () => {
      if (thumbImage) {
        setImageNotFound(require("../../../../assets/defaultImg.png"));
      } else {
        checkImageExists(image?.thum_image_sourc);
      }
    };
    return null;
  };

  const handleZoomReset = () => {
    setZoomResetFlag((prev) => !prev);
  };

  useEffect(() => {
    if (imgs?.length) {
      checkImageExists(imgs?.[activeImg]);
    } else {
      setImageNotFound(require("../../../../assets/defaultImg.png"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImg, imgs]);

  useEffect(() => {
    if (thumbnailSwiperRef.current) {
      thumbnailSwiperRef.current.slideTo(activeImg);
    }
  }, [activeImg]);

  return (
    <div
      className={`${styles.imgBlock} ${inModal ? styles.imgBlockModal : ""}`}
      style={imageBlockStyle}>
      {/* Thumbnails */}
      <div className={styles.thumbnailWrapper}>
        {windowWidth < 768 ? (
          <div className={"scrollRow"}>
            {loaded && imgs?.length
              ? imgs?.map((img, index) => (
                  <SwiperSlide
                    className={`${styles.miniImg} ${
                      activeImg === index && styles.miniImgActive
                    }`}
                    onClick={() => {
                      windowWidth > 768
                        ? setActiveImg(index)
                        : swiperRef.current?.slideTo(index);
                    }}
                    key={index}>
                    <img
                      src={
                        img?.video_image
                          ? process.env.REACT_APP_STORAGE_URL + img?.video_image
                          : process.env.REACT_APP_STORAGE_URL +
                            img?.thum_image_sourc
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                          process.env.REACT_APP_STORAGE_URL +
                          img?.images_source;
                      }}
                      alt={""}
                    />
                  </SwiperSlide>
                ))
              : !loaded
              ? Array.from({ length: 6 }).map((_, index) => (
                  <SwiperSlide
                    key={index}
                    className={styles.miniImg}>
                    <LoadContent containerClassName={styles.miniLoader} />
                  </SwiperSlide>
                ))
              : null}
          </div>
        ) : (
          <Swiper
            grabCursor={true}
            direction={windowWidth > 1200 ? "vertical" : "horizontal"}
            className={styles.miniImgs}
            slidesPerView={"auto"}
            ref={swiperRef}
            spaceBetween={18}
            onBeforeInit={(swiper) => {
              thumbnailSwiperRef.current = swiper;
            }}>
            {loaded && imgs?.length
              ? imgs?.map((img, index) => (
                  <SwiperSlide
                    className={`${styles.miniImg} ${
                      activeImg === index && styles.miniImgActive
                    }`}
                    onClick={() => {
                      windowWidth > 768
                        ? setActiveImg(index)
                        : swiperRef.current?.slideTo(index);
                      handleZoomReset();
                    }}
                    key={index}>
                    <img
                      src={
                        img?.video_image
                          ? process.env.REACT_APP_STORAGE_URL + img?.video_image
                          : process.env.REACT_APP_STORAGE_URL +
                            img?.thum_image_sourc
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src =
                          process.env.REACT_APP_STORAGE_URL +
                          img?.images_source;
                      }}
                      alt={""}
                    />
                  </SwiperSlide>
                ))
              : !loaded
              ? Array.from({ length: 6 }).map((_, index) => (
                  <SwiperSlide
                    key={index}
                    className={styles.miniImg}>
                    <LoadContent containerClassName={styles.miniLoader} />
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        )}
      </div>

      {/* Main Image */}
      <div
        className={`${styles.mainImgWrapper} ${
          inModal ? styles.mainImgWrapperModal : ""
        }`}
        onMouseLeave={() => setIsOverImage(false)}
        onMouseOver={() => setIsOverImage(true)}>
        {loaded ? (
          windowWidth > 768 ? (
            <div className={styles.zoomContainer}>
              {imageNotFound ? (
                <div
                  style={{
                    background: "rgb(239,239,245)",
                    width: "100%",
                    minHeight: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <img
                    style={{ background: "transparent" }}
                    src={require("../../../../assets/defaultImg.png")}
                    alt={""}
                  />
                </div>
              ) : imgs[activeImg].video_link ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={imgs[activeImg].video_link}
                  title="Youtube video"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen></iframe>
              ) : (
                <ZoomImage
                  activeImg={activeImg}
                  imgs={imgs}
                  onOpenModal={() => handleOpenModal(activeImg)}
                  isOverImage={isOverImage}
                  zoomResetFlag={zoomResetFlag}
                />
              )}
            </div>
          ) : imgs.length ? (
            <Swiper
              grabCursor={true}
              className={styles.mainImg}
              slidesPerView={1}
              zoom={{
                maxRatio: 3,
                toggle: true,
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              modules={[Zoom]}
              onSlideChange={(e) => {
                setActiveImg(e.activeIndex);
              }}>
              {imgs?.map((img, index) => (
                <SwiperSlide
                  key={index}
                  zoom={imgs[activeImg].video_link ? false : true}
                  className={styles.mainImg}
                  onClick={() => {
                    handleOpenModal(index);
                    handleZoomReset();
                  }}>
                  {imgs[activeImg].video_link ? (
                    <iframe
                      width="100%"
                      height="100%"
                      src={imgs[activeImg].video_link}
                      title="Youtube video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen></iframe>
                  ) : (
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        imgs[activeImg]?.images_source
                      }
                      alt={imgs[activeImg]?.images_source}
                      style={{
                        width: "100%",
                        maxHeight: "100%",
                        height: "100%",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../../../assets/defaultImg.png");
                      }}
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div
              style={{
                background: "rgb(239,239,245)",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}>
              <img
                style={{ background: "transparent" }}
                src={require("../../../../assets/defaultImg.png")}
                alt={""}
              />
            </div>
          )
        ) : (
          <LoadContent
            containerClassName={styles.mainImg}
            containerStyle={{ maxHeight: "92vw", height: "100%" }}
          />
        )}

        {/* Navigation Arrows */}
        {windowWidth > 768 &&
          !!imgs?.length &&
          imgs.length > 1 &&
          isOverImage && (
            <>
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                onClick={() => {
                  if (activeImg !== 0) {
                    setActiveImg(activeImg - 1);
                  } else {
                    setActiveImg(imgs.length - 1);
                  }
                  handleZoomReset();
                }}>
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                onClick={() => {
                  if (activeImg !== imgs.length - 1) {
                    setActiveImg(activeImg + 1);
                  } else {
                    setActiveImg(0);
                  }
                  handleZoomReset();
                }}>
                <SliderNextArrowSvg />
              </div>
            </>
          )}
      </div>

      {inModal && <div />}
    </div>
  );
}
