import React from "react";

const CardBackground = ({ width }) => {
  return width > 375 ? (
    <svg
      width="159"
      height="252"
      viewBox="0 0 159 252"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4.99999C0 2.23857 2.23858 0 5 0H154C156.761 0 159 2.23858 159 5V217C159 219.761 156.761 222 154 222L135.343 222C132.582 222 130.343 224.239 130.343 227V246.625C130.343 249.386 128.104 251.625 125.343 251.625H4.99999C2.23857 251.625 0 249.386 0 246.625V4.99999Z"
        fill="white"
      />
      <path
        d="M5 0.5H154C156.485 0.5 158.5 2.51472 158.5 5V217C158.5 219.485 156.485 221.5 154 221.5L135.343 221.5C132.305 221.5 129.843 223.962 129.843 227V246.625C129.843 249.11 127.828 251.125 125.343 251.125H4.99999C2.51471 251.125 0.5 249.11 0.5 246.625V4.99999C0.5 2.51471 2.51472 0.5 5 0.5Z"
        stroke="#939393"
        strokeOpacity="0.3"
        strokeWidth="1"
      />
    </svg>
  ) : (
    <svg
      width="133"
      height="324"
      viewBox="0 0 133 324"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.62517e-05 6.00004C7.27616e-06 2.68633 2.6863 5.98746e-06 6.00002 5.83462e-06L126.5 2.76741e-07C129.814 1.23901e-07 132.5 2.68629 132.5 6V286.215C132.5 289.529 129.814 292.215 126.5 292.215H108C104.686 292.215 102 294.902 102 298.215V318C102 321.314 99.3137 324 96 324H6.00087C2.68717 324 0.000870309 321.314 0.000861334 318L1.62517e-05 6.00004Z"
        fill="white"
      />
      <path
        d="M6.00002 1.00001L126.5 1C129.261 1 131.5 3.23858 131.5 6V286.215C131.5 288.977 129.261 291.215 126.5 291.215H108C104.134 291.215 101 294.349 101 298.215V318C101 320.761 98.7614 323 96 323H6.00087C3.23945 323 1.00087 320.761 1.00086 318L1.00002 6.00004C1.00001 3.23861 3.23859 1.00001 6.00002 1.00001Z"
        stroke="#939393"
        strokeOpacity="0.3"
        strokeWidth="1"
      />
    </svg>
  );
};

export default CardBackground;
