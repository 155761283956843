import React, { memo, useEffect } from "react";
import "./Stories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { getStoriesRequest } from "./getStoriesSlice";
import LoadContent from "components/LoadContent";
import useWindowSize from "components/useWindowSize";

const StoriesComponent = ({
  setSelectedStoryIndex,
  setIsOpen,
  handleFullScreenToggle,
}) => {
  const dispatch = useDispatch();
  const { get_stories, imagesPath, loading, stories_title } = useSelector(
    (state) => state.getStoriesSlice
  );
  const { currentLanguage } = useSelector(({ main }) => main);
  const { width: windowWidth } = useWindowSize();
  useEffect(() => {
    dispatch(getStoriesRequest());
  }, [dispatch]);

  const handleStoryClick = (index) => {
    setSelectedStoryIndex(index);
    setIsOpen(true);
    handleFullScreenToggle();
  };

  return (
    <div className="wrapper">
      <h3 className={"title"}>{stories_title[`title_${currentLanguage}`]}</h3>
      {windowWidth < 768 ? (
        <div className="stories__list">
          {loading ? (
            <div className="stories__wrapper scrollRow">
              {Array.from({ length: 9 }).map((_, ind) => (
                <LoadContent
                  containerClassName={"StoryLoader"}
                  key={ind}
                />
              ))}
            </div>
          ) : (
            <div className="stories__wrapper scrollRow">
              {get_stories.slice(0, 16)?.map((item, index) => (
                <div
                  key={index}
                  className="swiper-slide"
                  onClick={() => handleStoryClick(index)}>
                  <div>
                    <img
                      src={imagesPath + "/" + item.main_photo}
                      alt=""
                    />
                  </div>
                  <p className={"StoriesTitle"}>
                    {item["title_" + currentLanguage]}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <Swiper
          navigation={windowWidth > 425 ? true : false}
          slidesPerView={"auto"}
          spaceBetween={windowWidth > 425 ? 30 : 20}
          modules={[Navigation]}
          loop={false}
          className="stories__wrapper">
          {loading ? (
            <div
              style={{
                display: "flex",
                gap: windowWidth > 425 ? 30 : 20,
              }}>
              {Array.from({ length: 9 }).map((_, ind) => (
                <LoadContent
                  containerClassName={"StoryLoader"}
                  key={ind}
                />
              ))}
            </div>
          ) : (
            get_stories.slice(0, 16)?.map((item, index) => (
              <SwiperSlide
                key={index}
                onClick={() => handleStoryClick(index)}>
                <div>
                  <img
                    src={imagesPath + "/" + item.main_photo}
                    alt=""
                  />
                </div>
                <p className={"StoriesTitle"}>
                  {item["title_" + currentLanguage]}
                </p>
              </SwiperSlide>
            ))
          )}
        </Swiper>
      )}
    </div>
  );
};

export const Stories = memo(StoriesComponent);
