import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: false,
  error: null,
};

export const sandBuyNowRequest = createAsyncThunk(
  "sand_buy_now/sandBuyNowRequest",
  async ({ story_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/story-buy-now`,
        {
          story_id,
        },
        {
          params: {
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const sandBuyNowSlice = createSlice({
  name: "sand_buy_now",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sandBuyNowRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sandBuyNowRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(sandBuyNowRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default sandBuyNowSlice.reducer;
