import * as React from "react"
const StoryPause = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M15 7H8v23h7V7ZM29 7h-7v23h7V7Z"
    />
  </svg>
)
export default StoryPause
