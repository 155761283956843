import React from "react";
import styles from "./GiftItem.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import IsAvailable from "assets/SVG/IsAvailable";

const GiftItem = ({ prod }) => {
  const { currentLanguage } = useSelector(({ main }) => main);
  return (
    <Link
      to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/Product/${
        prod?.product_id
      }`}
      className={styles.GiftItem}>
      {/* <IsAvailable style={{ minWidth: "12.29px", minHeight: "12.29px" }} /> */}
      <img
        src={process.env.REACT_APP_STORAGE_URL + prod?.image}
        alt=""
        className={styles.GiftImage}
      />

      <p className={styles.GiftText}>{prod?.product_name}</p>
    </Link>
  );
};

export { GiftItem };
