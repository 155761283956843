import React, { memo, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  addCardStore,
  addCompare,
  addCompares,
  addShopCartCount,
  removeCompares,
  setCompareProductAnimation,
  setShopCartProductAnimation,
} from "../../../../store/MainSlice";
import ShopCartSvg from "../../../../assets/SVG/ShopCartSvg";
import CompareSvg from "../../../../assets/SVG/CompareSvg";
import { credit36Month } from "../../../../helpers/creditCalculator";
import UseOutSideClick from "../../../../helpers/UseOutSideClick";
import langData from "../../../../lang/data";
import UseCalcPrice from "../../../../helpers/UseCalcPrice";
import { Link } from "react-router-dom";
import checkBase64 from "helpers/checkBase64";

export default memo(function SearchModal({
  closeSearchModal,
  setCloseSearchModal,
}) {
  const [active, setActive] = useState(0);
  const [mouseIn, setMouseIn] = useState(false);

  const { searchInfo, currentLanguage, currentCurrency, compares } =
    useSelector(({ main }) => main);
  const containerRef = useRef();
  const productImageRef = useRef();
  const dispatch = useDispatch();
  UseOutSideClick(containerRef, () => setCloseSearchModal(true));

  // if (!closeSearchModal) {
  //   document.body.style.overflow = "hidden";
  // } else {
  //   document.body.style.overflow = "auto";
  // }

  if (!Object.keys(searchInfo || {}).length || closeSearchModal) return null;

  return (
    <div
      ref={containerRef}
      className={styles.container}>
      <div className={styles.main}>
        {Object.keys(searchInfo?.brand || {}).length ? (
          <Link
            className={styles.searchedProduct}
            to={`/brand/${searchInfo?.brand?.slug}/category`}>
            <div className={styles.searchedProdName}>
              <img
                className={styles.img}
                src={
                  checkBase64(searchInfo?.brand?.logo)
                    ? "data:image/png;base64," + searchInfo?.brand?.logo
                    : process.env.REACT_APP_STORAGE_URL +
                      searchInfo?.brand?.logo
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../../assets/defaultImg.png");
                }}
                alt={searchInfo?.brand?.name}
              />
              <span className={styles.name}>{searchInfo?.brand?.name}</span>
            </div>
          </Link>
        ) : null}
        {Object.keys(searchInfo?.category || {}).length ? (
          <Link
            className={styles.searchedProduct}
            to={
              !searchInfo?.category?.parent_id
                ? `/category/${searchInfo?.category?.slug}`
                : `/category/filter/${searchInfo?.category?.slug}`
            }>
            <div className={styles.searchedProdName}>
              <img
                className={styles.img}
                src={
                  process.env.REACT_APP_STORAGE_URL + searchInfo?.category?.icon
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../../assets/defaultImg.png");
                }}
                alt={searchInfo?.category?.["name_" + currentLanguage]}
              />
              <span className={styles.name}>
                {searchInfo?.category?.["name_" + currentLanguage]}
              </span>
            </div>
          </Link>
        ) : null}
        {Object.keys(searchInfo?.category || {}).length &&
        Object.keys(searchInfo?.brand || {}).length ? (
          <Link
            className={styles.searchedProduct}
            to={`/category/filter/${searchInfo?.category?.slug}?b=${searchInfo?.brand?.id}`}>
            <div className={styles.searchedProdName}>
              <img
                className={styles.img}
                src={
                  checkBase64(searchInfo?.brand?.logo)
                    ? "data:image/png;base64," + searchInfo?.brand?.logo
                    : process.env.REACT_APP_STORAGE_URL +
                      searchInfo?.brand?.logo
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../../assets/defaultImg.png");
                }}
                alt={searchInfo?.brand?.name}
              />
              <span className={styles.name}>
                {searchInfo?.brand?.name},{" "}
                {searchInfo?.category?.["name_" + currentLanguage]}
              </span>
            </div>
          </Link>
        ) : null}
        {searchInfo?.products?.map((product, index) => {
          const calculateFinalPrice = (price) => {
            const category = product?.product?.categories?.[0];

            if (category?.installation !== 0 && category?.installing === 0) {
              return (
                price -
                (product?.product?.installing_price ||
                  product?.installing_price)
              );
            }

            return price;
          };
          return (
            <Link
              key={index}
              onClick={() => setCloseSearchModal(true)}
              className={styles.searchedProduct}
              to={`${
                currentLanguage !== "hy" ? "/" + currentLanguage : ""
              }/Product/${product?.id}`}>
              <div className={styles.searchedProdName}>
                <img
                  className={styles.img}
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    (product?.product?.gallary_images_api?.[0]?.images_source ||
                      product?.product?.thumbnail_image_source)
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require("../../../../assets/defaultImg.png");
                  }}
                  alt={product?.product?.product_name}
                />
                <div className={styles.nameCodeProds}>
                  <span className={styles.name}>
                    {product?.product?.brand?.name}{" "}
                    {product?.product?.product_name}
                  </span>
                  <span className={styles.codeOfProd}>
                    00{product?.product?.li_product_id}
                  </span>
                </div>
              </div>
              <div className={styles.searchedProdName}>
                {!product?.promo_price &&
                !(
                  product?.product?.online_price &&
                  product?.product?.online_selling_price
                ) ? (
                  <p className={styles.price}>
                    {UseCalcPrice(
                      calculateFinalPrice(
                        product?.product?.average_price ||
                          product?.skus[0]?.selling_price
                      ),
                      currentCurrency
                    )}
                  </p>
                ) : (
                  <div className={styles.promoPriceContainer}>
                    <p className={styles.newPrice}>
                      {UseCalcPrice(
                        calculateFinalPrice(
                          product?.promo_price ||
                            (product?.product?.online_price &&
                              product?.product?.online_selling_price)
                        ),
                        currentCurrency
                      )}
                    </p>
                    <div className={styles.oldPriceContainer}>
                      <p className={styles.oldPrice}>
                        {UseCalcPrice(
                          calculateFinalPrice(product?.skus[0]?.selling_price),
                          currentCurrency
                        )}
                      </p>

                      <span className={styles.line} />
                    </div>
                  </div>
                )}
                {product?.product?.cashback ? (
                  <div className={styles.cashbackContainer}>
                    <span className={styles.cashbackText}>Cashback</span>
                    <span className={styles.cashbackPrice}>
                      {UseCalcPrice(
                        product?.product?.cashback,
                        currentCurrency
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
              {window.innerWidth > 992 ? (
                <div
                  className={styles.searchedProdHover}
                  onMouseMove={() => setMouseIn(true)}
                  onMouseOut={() => setMouseIn(false)}>
                  <div className={styles.prodSlide}>
                    <img
                      className={styles.brand}
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        product?.product?.brand?.logo
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../../../assets/defaultImg.png");
                        currentTarget.style["max-width"] = "32px";
                        currentTarget.style["max-height"] = "19px";
                      }}
                      alt={product?.product?.brand?.name}
                    />
                    <div
                      style={{
                        width: "100%",
                        height: "176px",
                        position: "relative",
                      }}>
                      <Link
                        onClick={() => setCloseSearchModal(true)}
                        style={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: "5px",
                        }}
                        ref={productImageRef}
                        to={`${
                          currentLanguage !== "hy" ? "/" + currentLanguage : ""
                        }/Product/${product?.id}`}>
                        {(product?.product?.gallary_images_api?.length
                          ? product?.product?.gallary_images_api
                          : Array.from({ length: 1 })
                        ).map((_, index) => (
                          <div
                            key={index}
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              width: "100%",
                              height: "100%",
                              marginRight: "5px",
                              borderBottom:
                                "1.5px solid " +
                                (index === active
                                  ? "rgb(227, 19, 53)"
                                  : "#d9d9d9"),
                              zIndex: 99,
                              opacity: +mouseIn,
                            }}
                            onMouseMove={() => setActive(index)}
                          />
                        ))}
                      </Link>
                      {product?.product?.gallary_images_api?.length ? (
                        product?.product?.gallary_images_api?.map(
                          (item, index) => (
                            <div
                              style={{
                                zIndex: index,
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                paddingBottom: "5px",
                              }}
                              key={index}>
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                  opacity: active === index ? 1 : 0,
                                }}
                                alt={
                                  product?.product?.brand?.name +
                                  " " +
                                  product?.product?.product_name
                                }
                                src={
                                  process.env.REACT_APP_STORAGE_URL +
                                  item?.images_source
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = require("../../../../assets/defaultImg.png");
                                }}
                              />
                            </div>
                          )
                        )
                      ) : (
                        <div
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            paddingBottom: "5px",
                          }}>
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            alt={
                              product?.product?.brand?.name +
                              " " +
                              product?.product?.product_name
                            }
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              product?.product?.thumbnail_image_source
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = require("../../../../assets/defaultImg.png");
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.descContainer}>
                      <Link
                        onClick={() => setCloseSearchModal(true)}
                        to={`${
                          currentLanguage !== "hy" ? "/" + currentLanguage : ""
                        }/Product/${product?.id}`}>
                        {/* card */}
                        {!product?.promo_price &&
                        !(
                          product?.product?.online_price &&
                          product?.product?.online_selling_price
                        ) ? (
                          <p className={styles.price}>
                            {UseCalcPrice(
                              calculateFinalPrice(
                                product?.product?.average_price ||
                                  product?.skus[0]?.selling_price
                              ),
                              currentCurrency
                            )}
                          </p>
                        ) : (
                          <div className={styles.promoPriceContainer}>
                            <p className={styles.newPrice}>
                              {UseCalcPrice(
                                calculateFinalPrice(
                                  product?.promo_price ||
                                    (product?.product?.online_price &&
                                      product?.product?.online_selling_price)
                                ),
                                currentCurrency
                              )}
                            </p>
                            <div className={styles.oldPriceContainer}>
                              <p className={styles.oldPrice}>
                                {UseCalcPrice(
                                  calculateFinalPrice(
                                    product?.skus[0]?.selling_price
                                  ),
                                  currentCurrency
                                )}
                              </p>

                              <span className={styles.line} />
                            </div>
                          </div>
                        )}
                        {product?.product?.cashback ? (
                          <div>
                            <span className={styles.cashbackText}>
                              Cashback
                            </span>
                            <span
                              className={styles.cashbackPrice}
                              style={{ marginLeft: "5px" }}>
                              {UseCalcPrice(
                                product?.product?.cashback,
                                currentCurrency
                              )}
                            </span>
                          </div>
                        ) : null}
                        <p className={styles.productName}>
                          {product?.product?.brand?.name +
                            " " +
                            product?.product?.product_name}
                        </p>

                        {credit36Month(
                          product?.promo_price ||
                            product?.skus[0]?.selling_price
                        ) && (
                          <div className={styles.priceMounth}>
                            {UseCalcPrice(
                              credit36Month(
                                product?.promo_price ||
                                  product?.skus[0]?.selling_price
                              ),
                              currentCurrency
                            )}{" "}
                            / {langData.month[currentLanguage]}
                          </div>
                        )}
                      </Link>

                      <div className={styles.btnsContainer}>
                        <div
                          className={styles.cardBtn}
                          onClick={() => {
                            if (
                              product?.product?.gallary_images_api?.[0] ||
                              product?.product?.thumbnail_image_source
                            ) {
                              const { top, left } =
                                productImageRef.current?.getBoundingClientRect();

                              dispatch(
                                setShopCartProductAnimation({
                                  image:
                                    product?.product?.gallary_images_api?.[0]
                                      ?.images_source ||
                                    product?.product?.thumbnail_image_source,
                                  position: {
                                    top,
                                    left,
                                  },
                                })
                              );
                            }
                            dispatch(
                              addShopCartCount(
                                +product?.promo_price ||
                                  +(
                                    product?.online_price &&
                                    product?.online_selling_price
                                  ) ||
                                  +product?.skus[0]?.selling_price
                              )
                            );
                            dispatch(
                              addCardStore({
                                price:
                                  +product?.promo_price ||
                                  +(
                                    product?.online_price &&
                                    product?.online_selling_price
                                  ) ||
                                  +product?.skus[0]?.selling_price,
                                qty: 1,
                                product_id: product?.skus?.[0]?.id,
                                seller_id: 1,
                                shipping_method_id: 0,
                                type: "product",
                                is_buy_now: "no",
                              })
                            );
                          }}>
                          <ShopCartSvg className={styles.cardBtnSvg} />
                          <span>
                            {langData.buttons.add_to_cart[currentLanguage]}
                          </span>
                        </div>
                        <div
                          onClick={() => {
                            if (
                              (product?.product?.gallary_images_api?.[0] ||
                                product?.product?.thumbnail_image_source) &&
                              !compares.length
                            ) {
                              const { top, left } =
                                productImageRef.current?.getBoundingClientRect();
                              dispatch(
                                setCompareProductAnimation({
                                  image:
                                    product?.product?.gallary_images_api?.[0]
                                      ?.images_source ||
                                    product?.product?.thumbnail_image_source,
                                  position: {
                                    top,
                                    left,
                                  },
                                })
                              );
                            }
                            if (!compares.length) {
                              dispatch(addCompares(product.id));
                            } else {
                              dispatch(removeCompares(product.id));
                            }

                            dispatch(
                              addCompare({
                                product_sku_id: product.skus?.[0].id,
                                data_type: product.product.product_type,
                              })
                            );
                          }}
                          className={styles.compareBtn}>
                          <CompareSvg active={compares.length} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Link>
          );
        })}
      </div>
    </div>
  );
});
